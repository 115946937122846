<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        color="info"
      >
        <v-toolbar-title>
          Pesquisar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <v-text-field
              v-model="filters.nickname"
              clearable
              hide-details="auto"
              label="Nome Fantasia"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="filters.cnpj"
              v-mask="'##.###.###/####-##'"
              hide-details="auto"
              clearable
              label="CNPJ"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="filters.activityBranchId"
              :items="activityBranches"
              :loading="loadingActivityBranches"
              clearable
              item-text="description"
              item-value="id"
              label="Ramo de Atividade"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="info"
          outlined
          @click="filter"
        >
          <v-icon
            left
            v-text="'mdi-filter'"
          />
          Pesquisar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import activityBranchesApi from '@/api/activity-branches'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        loadingActivityBranches: false,
        activityBranches: [],
        filters: {},
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {
      this.loadActivityBranches()
    },

    methods: {
      async loadActivityBranches () {
       try {
          this.loadingActivityBranches = true

          const response = await activityBranchesApi.list()

          this.activityBranches = response.data.branches
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingActivityBranches = false
        }
      },

      filter () {
        this.show = false
        this.$emit('filter', this.filters)
      },
    },

  }
</script>
